import {
  mapActions, mapGetters, mapMutations,
} from 'vuex';
import { isNullOrWhiteSpace } from '@/utils';
import {
  Sidebar,
  Map,
  Tag,
  UserCard,
  EventParticipantsAdmin,
  ShareModal,
  OrderStatusModal,
  ConfirmationModal,
  Advertisement,
} from '@/components';

export default {
  metaInfo() {
    return {
      title: 'Event detailpage',
      meta: [
        { property: 'og:title', content: `Ik doe mee aan ${this.event.name} #IKwatersport` },
        { property: 'og:site_name', content: `WWSV ${this.event.name}` },
        { property: 'og:description', content: this.event.description !== undefined ? this.$options.filters.striphtml(this.event.description) : '' },
        { property: 'og:image', content: this.event.hasPicture ? this.event.profilePicture : 'https://ikwatersport.be/img/home.png' },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:image', content: this.event.hasPicture ? this.event.profilePicture : 'https://ikwatersport.be/img/home.png' },
        { property: 'twitter:image:alt', content: this.event.name },
        { property: 'twitter:description', content: this.event.description !== undefined ? this.$options.filters.striphtml(this.event.description) : '' },
      ],
    };
  },
  name: 'EventDetail',
  components: {
    [Sidebar.name]: Sidebar,
    [Map.name]: Map,
    [Tag.name]: Tag,
    [UserCard.name]: UserCard,
    [EventParticipantsAdmin.name]: EventParticipantsAdmin,
    [ShareModal.name]: ShareModal,
    [OrderStatusModal.name]: OrderStatusModal,
    [ConfirmationModal.name]: ConfirmationModal,
    [Advertisement.name]: Advertisement,
  },
  props: {},
  data: () => ({
    showStatusModal: false,
    showShareModal: false,
    shareModalIsActive: false,
    showConfirmationModal: false,
    showConfirmationModalDelete: false,
    confirmationModalIsActive: false,
    confirmationModalDeleteIsActive: false,
    activetab: 1,
    sidebarInfo: undefined,
    map: {
      zoom: 14,
      events: [],
      center: [3.0, 51.0],
      showEvents: true,
    },
    error: false,
    mapLoaded: false,
    participantsCurrentPage: 1,
    participantsPageSize: 1000,
    showRightArrow: false,
    showLeftArrow: false,
    searchQuery: '',
    searchQueryPast: '',
    advertisement: null,
  }),
  computed: {
    ...mapGetters('advertisement', {
      bestMatch: 'getBestmatch',
    }),
    ...mapGetters('event', {
      event: 'getEvent',
      participants: 'getParticipants',
    }),
    website() {
      if (this.event.club.website.includes('http')) return this.event.club.website;
      return `//${this.event.club.website}`;
    },
    registrationIsClosed() {
      if (this.event.availablePlaces <= 0) { return true; }
      if (new Date(this.event.registrationPossibleUntil) <= new Date()) { return true; }
      if (!this.event.registrationPossibleUntil && new Date(this.event.from) <= new Date()) { return true; }

      return false;
    },
  },
  methods: {
    ...mapActions('advertisement', {
      initialiseAdvertisementBestmatch: 'FETCH_DATA_ADVERTISEMENT_BESTMATCH',
    }),
    ...mapActions('event', {
      initialiseEvent: 'FETCH_DATA_EVENT',
      fetchEventParticipants: 'FETCH_DATA_EVENT_PARTICIPANTS_LIST',
      removeParticipant: 'REMOVE_PARTICIPANT',
      deleteEvent: 'DELETE_EVENT',
    }),
    ...mapMutations('event', {
      clearEventParticipantsList: 'CLEAR_EVENT_PARTICIPANTS_LIST',
    }),
    isAdmin() {
      return !!(this.$isAdmin || this.event.currentUserIsAdmin);
    },
    refreshEventParticipants() {
      this.clearEventParticipantsList();
      const paramsObj = {
        id: this.event.id,
        page: this.participantsCurrentPage,
        pageSize: this.participantsPageSize,
        isAdmin: this.isAdmin(),
        searchPast: this.searchQueryPast,
        search: this.searchQuery,
      };
      this.fetchEventParticipants(paramsObj);
    },
    searchEventParticipants(searchQueries) {
      this.clearEventParticipantsList();
      this.searchQuery = searchQueries.searchQuery;
      this.searchQueryPast = searchQueries.searchQueryPast;
      const paramsObj = {
        id: this.event.id,
        page: 1,
        pageSize: this.participantsPageSize,
        isAdmin: this.isAdmin(),
        search: this.searchQuery,
        searchPast: this.searchQueryPast,
      };
      this.fetchEventParticipants(paramsObj);
    },
    closeModal() {
      // the shareModalIsActive is catches the clickOutside event that is emitted when clicking "deel"
      if (this.shareModalIsActive) {
        this.shareModalIsActive = !this.shareModalIsActive;
      } else {
        this.showShareModal = false;
      }
    },
    closeConfirmationModal() {
      if (this.confirmationModalIsActive) {
        this.confirmationModalIsActive = !this.confirmationModalIsActive;
      } else {
        this.showConfirmationModal = false;
      }
    },
    closeConfirmationModalDelete() {
      if (this.confirmationModalDeleteIsActive) {
        this.confirmationModalDeleteIsActive = !this.confirmationModalDeleteIsActive;
      } else {
        this.showConfirmationModalDelete = false;
      }
    },
    removeParticipantFunction() {
      const paramsObj = {
        eventId: this.event.id,
        participantId: this.participant.id,
      };
      this.closeConfirmationModal();
      this.removeParticipant(paramsObj).then(() => {
        this.refreshEventParticipants();
        this.$toasted.show(`${this.participant.firstname} ${this.participant.lastname} is verwijderd als deelnemer.`, { type: 'success' });
      }).catch((error) => {
        this.$toasted.show(...error.response.data);
      });
    },
    showOrderStatusModal() {
      const queryObj = this.$route.query;

      const isPaymentStatusActive = !isNullOrWhiteSpace(queryObj['payment-status']);
      const isOrderIdInQuery = !isNullOrWhiteSpace(queryObj['order-id']);

      if (!isPaymentStatusActive) {
        return;
      }

      if (!isOrderIdInQuery) {
        return;
      }

      this.orderId = queryObj['order-id'];
      this.showStatusModal = true;
    },
    showConfirmationModalFunction(rowData) {
      this.participant = rowData;
      this.confirmationModalIsActive = true;
      this.showConfirmationModal = true;
    },
    handleScroll(event) {
      const containerWidth = this.$refs['scroll-container'].clientWidth;
      const { scrollWidth } = event.target;
      const maxScroll = parseInt(scrollWidth - containerWidth, 10);

      var scrollEnd = event.target.scrollLeft === maxScroll;
      var scrollBegin = event.target.scrollLeft === 0;

      if (scrollEnd) {
        this.showRightArrow = false;
      } else {
        this.showRightArrow = true;
      }

      if (scrollBegin) {
        this.showLeftArrow = false;
      } else {
        this.showLeftArrow = true;
      }
    },
    softDeleteEvent(id) {
      this.deleteEvent(id).then(() => {
        this.$toasted.show('Event werd succesvol verwijderd', { type: 'success' });
        this.$router.push({ name: 'events' });
      });
    },
  },
  serverPrefetch() {
    return this.initialiseEvent(this.$route.params.eventurl).catch((error) => {
      if (error.response.status === 404) {
        this.$router.push({ name: '404' });
      }
    });
  },
  created() {
    this.clearEventParticipantsList();
    this.initialiseEvent(this.$route.params.eventurl)
      .then(() => {
        const params = {
          tags: this.event.eventTags,
        };
        this.initialiseAdvertisementBestmatch(params)
          .then(() => {
            this.advertisement = this.bestMatch;
          });
        const coordinates = [this.event.lat, this.event.long];
        this.map.events = [];
        this.map.events.push(this.event);
        this.map.center = coordinates;

        this.mapLoaded = true;

        const isAdmin = this.isAdmin();
        const paramsObj = {
          id: this.event.id,
          page: this.participantsCurrentPage,
          pageSize: this.participantsPageSize,
          search: this.searchQuery,
          searchPast: this.searchQueryPast,
          isAdmin,
        };
        if (this.$isLoggedIn) {
          this.fetchEventParticipants(paramsObj);
        }

        this.showOrderStatusModal();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: '404' });
        } else {
          this.$toasted.show(...error.response.data);
        }
      });
  },
  mounted() {
    setTimeout(() => {
      const containerWidth = this.$refs['scroll-container'].clientWidth;
      const { scrollWidth } = this.$refs['scroll-container'].children[0];
      const maxScroll = parseInt(scrollWidth - containerWidth, 10);

      if (maxScroll > 10) {
        this.showRightArrow = true;
      }
    }, 300);
  },
};
